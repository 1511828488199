<script setup lang="ts">
import { useActiveScroll } from 'vue-use-active-scroll';

type Anchor = { anchor: string; label: string; ref: HTMLElement | null };

export interface CiStickyNavigationProps {
  items?: Anchor[];
  useHtml?: boolean;
  jumpToFirst?: boolean;
  jumpToLast?: boolean;
}

const props = withDefaults(defineProps<CiStickyNavigationProps>(), {
  items: () => [],
  useHtml: false,
  jumpToFirst: false,
  jumpToLast: false,
});

const emit = defineEmits<{
  'item-clicked': [event: MouseEvent, anchor: string];
}>();

/********************
* COMPOSITIONS      *
********************/
const route = useRoute();

/********************
 * REFS & VARS       *
 ********************/
const targets = ref([]);
const scrollableContainerRef = ref<HTMLElement | null>(null);
const scrollableRef = ref<HTMLElement | null>(null);

const { showRightGradient, showLeftGradient } = useScrollable({
  scrollableContainerRef,
  scrollableRef,
});

const { setActive, activeId } = useActiveScroll(targets, {
  jumpToFirst: props.jumpToFirst,
  jumpToLast: props.jumpToLast,
  replaceHash: false,
  overlayHeight: 48,
});

watch(() => props.items, onItemsChange, { immediate: true });
watch(() => activeId.value, onItemChange, { immediate: true });

function onItemsChange(newValue: Anchor[]) {
  targets.value = newValue.map(item => item.ref).filter(item => item);
}

function onItemChange(activeId: string) {
  if (scrollableRef.value && activeId) {
    const activeAnchor: HTMLElement = scrollableRef.value.querySelectorAll(`[data-id=${activeId}]`)[0];
    nextTick(() => {
      scrollableRef.value?.scroll?.({
        left: activeAnchor?.offsetLeft - window.innerWidth / 2 + activeAnchor?.getBoundingClientRect().width / 2,
        behavior: 'smooth',
      });
    });
  }
}

function onNavItemClicked(event: MouseEvent, item: Anchor) {
  setActive(item.anchor);
  emit('item-clicked', event, item.anchor);
}
</script>

<template>
  <div
    ref="scrollableContainerRef"
    class="sticky top-0 z-30 min-h-[46px] border-b-2 border-gray-40 bg-gray-10"
  >
    <div
      ref="scrollableRef"
      class="navigation__scrollable overflow-auto"
      style="-webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none"
    >
      <client-only>
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <div class="flex flex-row lg:justify-center">
                <NuxtLink
                  v-for="item in props.items"
                  :key="item.anchor"
                  :to="{ name: route.name, params: route.params, query: route.query, hash: `#${item.anchor}`, replace: true }"
                  class="whitespace-nowrap border-y-[3px] px-6 py-2 hover:border-b-gray-40 hover:no-underline"
                  :class="[item.anchor === activeId ? 'border-b-gray-40 border-t-transparent' : 'border-transparent']"
                  :data-id="item.anchor"
                  @click.capture.prevent="onNavItemClicked($event, item)"
                >
                  <span
                    v-if="props.useHtml"
                    class="flex items-center"
                    v-html="item.label"
                  />
                  <span v-else>{{ item.label }}</span>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </client-only>
    </div>
    <div
      v-if="showLeftGradient"
      class="absolute left-0 top-0 h-[calc(100%-2px)] w-[30px]"
      style="background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #f5f5f5 100%)"
    />
    <div
      v-if="showRightGradient"
      class="absolute right-0 top-0 h-[calc(100%-2px)] w-[30px]"
      style="background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f5f5f5 100%)"
    />
  </div>
</template>

<style></style>
